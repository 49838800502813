<template>
<div>
	<div class="pure-g login-form">
		<div class="pure-u-1 pure-u-md-6-24 pure-u-lg-7-24 pure-u-xl-8-24"></div>
		<div class="pure-u-1 pure-u-md-12-24 pure-u-lg-10-24 pure-u-xl-8-24 padding-v margin-v">
			<form class="login pure-g" @submit.prevent="login">
				<vue-element-loading :active="authStatus == 'loading'"/>
				<h1 class="pure-u-1">Войти</h1>
				<div class="pure-u-1 form-row">
					<label>E-mail</label>
					<input required v-model="username" type="text" placeholder="Логин"/>
				</div>
				<div class="pure-u-1 form-row">
					<label>Пароль</label>
					<input required v-model="password" type="password" placeholder="Пароль"/>
				</div>
        <div class="pure-u-1 form-row error">
          {{ error }}
        </div>

				<div class="pure-u-1 pure-u-md-1-2"></div>
				<button class="pure-u-1 pure-u-md-1-2 btn btn-primary" type="submit">
					<div class="loader"></div>
					Войти</button>
			</form>
		</div>
	</div>
</div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'

import { mapGetters } from "vuex";
import { AUTH_REQUEST } from "../store/actions/auth";

export default {
	name: 'Login',
	components: {
        VueElementLoading
    },
	data() {
		return {
			username: "",
			password: "",
      error: ""
		};
	},
	computed: {
		...mapGetters(["authStatus"])
	},
	methods: {
		login: function () {
      this.error = ""
      const { username, password } = this
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        this.$router.push('/')
      }).catch((e) => {
        if (e.err_code == 1001) {
          this.error = "Неверный логин или пароль"
        }
      })
		}
	}
}
</script>

<style scoped>
.error {
  color: #cc0101;
}
</style>