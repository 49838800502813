<template>
  <modal name="PopupEditTaskCell" @before-open="beforeOpen" @opened="opened" @before-close="beforeClose"
    draggable=".popup-header" :adaptive="true" :width="450" :scrollable="true" height="auto" :shiftX="shiftX" :shiftY="shiftY">

    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">
        <template v-if="task">
          {{ task.visibleId }}: {{ task.name }}
        </template>
        <b class="margin-l">{{ column.name }}</b>
      </span>
      <button class="btn btn-small btn-transparent pull-right" @click="onClose">
        <i class="fal fa-times"></i>
      </button>
    </div>
    
    <form class="content with-overflow padding" @submit.prevent="saveCell" ref="form">
      <div class="pure-g container">
        <vue-element-loading :active="isUpdatingCell" />
        <div class="pure-u-1-2 form-row txt-l">
          <label>Дата завершения</label>
          <v-date-picker v-model="cell.deadline" :min-date='new Date()'>
            <template v-slot="{ inputValue, togglePopover }">
              <input type="text" placeholder="Не задана" :value="inputValue" :disabled="!isEditCellAlowed" @click="togglePopover" />
            </template>
          </v-date-picker>
        </div>
        <div class="pure-u-1-2 form-row txt-l">
          <label>Оставшееся время работы</label>
          <hours-picker v-model="cell.expectedHours" :disabled="!isEditCellAlowed" />
        </div>
        <div class="pure-u-1 form-row txt-l">
          <label>Ответственные</label>
          <users-picker v-model="cell.assignedIds" :disabled="!isEditCellAlowed" :roles="column.rolesAssignable" :hiddenRoles="hiddenRoles" />

          <label v-if="cells.length > 1">
            <input type="checkbox" :disabled="!isEditCellAlowed" v-model="multipleSelectAppendAssigns" />
            Дополнить ответственных
          </label>
        </div>

        <div class="pure-u-1-2">
          <button type="button" class="btn btn-destruct" @click="onClose"><i class="fal fa-times"></i>
            Отменить</button>
        </div>
        <div class="pure-u-1-2 txt-r">
          <button type="submit" class="btn btn-primary" v-if="isEditCellAlowed"><i class="fal fa-check"></i>
            Сохранить</button>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from "vuex"

import VueElementLoading from 'vue-element-loading'
import UsersPicker from '@/components/UsersPicker.vue'
import HoursPicker from '@/components/HoursPicker.vue'

import apiCall from "@/utils/api"
import {
  EDIT_CELL_OWN, EDIT_CELL_OWN_TASK_LEFT, EDIT_CELL_ANY
} from "@/utils/permissions"
import { CELLS_SELECTION_STOP } from "@/store/actions/project"


export default {
  name: 'PopupEditTaskCell',
  components: {
    VueElementLoading,
    UsersPicker,
    HoursPicker
  },
  data() {
    return {
      cell: {},
      defaultCellData: {},  // preserve data to restore it on cancel
      cells: [],
      cellIndex: -1,
      maxAssignedCellIndex: -1,
      task: {},
      column: {},
      contextRef: null,
      isUpdatingCell: false,
      popupHeight: 218,
      hiddenRoles: [],

      // для множественного распределения чтоб можно было только добавлять тех назначенных, которых не было
      multipleSelectAppendAssigns: true
    }
  },
  computed: {
    ...mapGetters(["authId", "authRoles", "customerRoles"]),
    shiftX() {
      if (!this.contextRef) {
        return 0.5
      }
      let rect = this.contextRef.getBoundingClientRect()
      return rect.right / window.innerWidth
    },
    shiftY() {
      if (!this.contextRef) {
        return 0.5
      }
      let screenHeight = window.innerHeight
      let rect = this.contextRef.getBoundingClientRect()
      if (rect.bottom + this.popupHeight > screenHeight) {
        return (rect.top - this.popupHeight / 2) / screenHeight
      } else {
        return (rect.top + this.popupHeight / 2) / screenHeight
      }
    },
    isMeAssigned() {
      return !!(this.cell.assignedIds && this.cell.assignedIds.find(id => id == this.authId))
    },
    isEditCellAlowed() {
      if (this.authRoles.indexOf(EDIT_CELL_ANY) !== -1) {
        return true
      } else if (this.authRoles.indexOf(EDIT_CELL_OWN) !== -1 && this.isMeAssigned) {
        return true
      } else if (this.authRoles.indexOf(EDIT_CELL_OWN_TASK_LEFT) !== -1 && this.cellIndex <= this.maxAssignedCellIndex) {
        return true
      }
      return false
    },
  },
  methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length - 1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.hiddenRoles = []
      if (data.params.cell) {
        this.$set(this, 'cell', {...data.params.cell})
        this.cells = [data.params.cell]
        this.cellIndex = data.params.cellIndex
        this.maxAssignedCellIndex = data.params.maxAssignedCellIndex
      } else if (data.params.cells) {
        this.multipleSelectAppendAssigns = true
        this.cell = {...data.params.cells[0]}
        this.cells = data.params.cells
        this.maxAssignedCellIndex = data.params.maxAssignedCellIndex ?? 0
        this.cellIndex = 0
      }

      if (data.params.group && (data.params.group.specialType == 10 || data.params.group.specialType == 20)) {
        this.hiddenRoles = this.customerRoles
      }

      if (data.params.task) {
        this.task = data.params.task
      }
      this.$set(this, 'column', data.params.column)
      this.$set(this, 'contextRef', data.params.contextRef)
      this.$escStack.push(this.$options.name)
    },
    opened() {
      this.popupHeight = this.$refs.form.getBoundingClientRect().height
    },
    onClose() {
      this.$modal.hide(this.$options.name)
    },
    saveCell: function () {
      for (var i = 0; i < this.cells.length; i++) {
        this.cells[i].deadline = this.cell.deadline
        this.cells[i].expectedHours = this.cell.expectedHours
        if (this.cells.length > 1 && this.multipleSelectAppendAssigns) {
          //добавляем выбранные ids и удаляем повторы
          this.cells[i].assignedIds = this.cells[i].assignedIds.concat(this.cell.assignedIds).filter((item, index, array) => array.indexOf(item) === index)
        } else {
          this.cells[i].assignedIds = this.cell.assignedIds
        }

        apiCall({ url: "tasks/cell/edit/", method: "POST", data: this.cells[i] })
      }
      this.$modal.hide(this.$options.name)
      this.$store.dispatch(CELLS_SELECTION_STOP)
    },
  }
}
</script>
