<template>
<tr class="task" :id="'task_row_' + (task.id || task.pendingId)" @contextmenu.prevent="$emit('startEditTask', task)" v-bind:class="{
  'not-saved': !task.id,
  'outsource': task.isOutsource,
  'deleted': task.isDeleted,
  'filtered-out': !group.visibleTaskIds[task.id],
  'this-children-opened': taskChildrenOpenedId == task.id,
  'z-3': task.id && (((selectingFor || isLinkingTask) && task.id == selectingFor.id) || taskChildrenOpenedId == task.id),
  'mine': task.cells.some(c => c.assignedIds.some(uid => uid == authId))
}">
  <td class="cell uid single-line" v-bind:title="task.visibleId" @dblclick.prevent.stop="$refs.visibleIdEditor.startEdit()">
    <double-click-edit-text v-model="task.visibleId" ref="visibleIdEditor" @input="saveTask" placeholder="№" text-align="center"/>
  </td>
  <td class="cell name" @click="toggleChildren" @contextmenu.prevent.stop="openEditPopup" @dblclick.prevent.stop="$refs.taskNameEditor.startEdit()" :title="task.name"
    v-bind:class="{
      'bg-blink': !isLinked && task.id && taskShowing && taskShowing.id == task.id,
    }">
      <span class="flex-row block-vcenter padding-half-h inline-actions-wrp">
        <span class="inline-actions">
          <avatar v-if="task.createdById && !task.groupId" :userid="task.createdById" side="1.7rem"/>

          <i v-if="!isLinked && task.groupId && hasChildren" class="margin-half-r children-folder-icon" v-bind:class="{
            'fas fa-folder': !isChildrenOpened,
            'fad fa-folder-open': isChildrenOpened,
            'has-notifications': subTasksHasNotifications(task),
            'has-chat-notifications': subTasksHasChatNotifications(task),
          }"></i>
          <i v-else-if="!isLinked && task.groupId" class="margin-half-r children-folder-icon" style="opacity: .15"  v-bind:class="{
            'fas fa-folder': !isChildrenOpened,
            'fad fa-folder-open': isChildrenOpened,
            'has-notifications': subTasksHasNotifications(task),
            'has-chat-notifications': subTasksHasChatNotifications(task),
          }"></i>
          <i v-else-if="isLinked" class="far fa-link text-green margin-half-r"></i>

          <button v-if="!tasksSubscribed[task.id]" class="btn favorite" type="button"
            title="Подписаться на уведомления по этой задаче"
            @click.stop="subscribeTask(task)">
            <i class="fal fa-star"></i>
          </button>
          <button v-else class="btn favorite enabled" type="button"
            title="Отписаться от уведомления по этой задаче"
            @click.stop="unsubscribeTask(task)">
            <i class="fas fa-star"></i>
          </button>
        </span>
        <span class="flex-expand row-name">
          <span v-if="task.id && !task.groupId" class="linked-name">
            <span v-if="allUsersKeyed[task.createdById]" class="margin-r">{{ allUsersKeyed[task.createdById].fullName() }}</span>

            <span v-if="task.createdAt">
            {{ $moment.utc(task.createdAt).local().calendar() }}
            </span>
          </span>

          <double-click-edit-text v-else v-model="task.name" ref="taskNameEditor" @input="saveTask" placeholder="Введите название задачи"
            class="row-name__content"
            :enabled="isEditTaskAllowed"
            :class="{
              'notification': unreadNotifications
            }" />
          
          <span v-if="isLinked" class="linked-name">({{ tasksKeyed[task.parentTaskId].name }})</span>
          <span v-else-if="task.isOutsource && task.outsourceText" class="outsource-name">({{ task.outsourceText }})</span>
        </span>
        <span class="inline-actions hover">

          <button class="btn text-green" type="button"
            title="Прикрепить ссылку на эту задачу"
            v-if="!isLinked && task.parentTaskId && group.specialType != 10"
            v-show="!isLinkingTask"
            @click.stop="startLinkingTask(task)">
            <i class="far fa-link"></i>
          </button>

          <button class="btn text-green" type="button"
            title="Перейти к этой задаче"
            v-if="isLinked"
            @click.stop="startShowingTask(task)">
            <i class="far fa-external-link"></i>
          </button>

          <tippy v-if="teamIds.length && !user.isCustomer" :interactive="true" placement="bottom-end" trigger="click" :maxWidth="500" theme="light sodnp" ref="editTaskTippy" :onShow="onShowTaskTeam">
            <!-- Строка с названием -->
            <template v-slot:trigger>
              <button class="btn text-green" type="button"
                title="Команда задачи">
                <i class="fad fa-users"></i>
              </button>
            </template>

            <div class="team-users">
              <user-entry :id="uid" v-for="(uid, index) in teamIds" :key="index + '' + nesting + '' + group.id + '' + (linksGroup ? linksGroup.id : '0') + '' + task.id + '' + task.groupId + '' + ' team ' + uid" />
            </div>
          </tippy>

          <button class="btn text-green" type="button"
            v-if="task.hasWatchers && !user.isCustomer"
            title="Показать зависящие от этой задачи и тех, кто за ней наблюдает"
            @click.stop="$modal.show('PopupTaskSubscribers', {'task': task})">
            <i class="fad fa-eye"></i>
          </button>

          <button class="btn" type="button"
            v-if="task.description"
            v-bind:title="task.description"
            style="color: #00a26c"
            @click.stop="$modal.show('PopupAboutTask', task)">
            <i class="fad fa-question"></i>
          </button>

          <button class="btn stages text-green" type="button"
            v-if="!unreadNotifications && showHistoryButton"
            title="История ведения работ"
            v-bind="{'disabled': !task.hasStages}"
            @click.stop="$modal.show('PopupAllStages', {'task': task, 'group': group})">
            <i class="fad fa-scroll"></i>
          </button>
        </span>

        <span class="inline-actions">
          <button class="btn stages notification" type="button"
            v-if="unreadNotifications > 0 && showHistoryButton"
            title="История ведения работ"
            v-bind="{'disabled': !task.hasStages}"
            @click.stop="$modal.show('PopupAllStages', {'task': task, 'group': group})">
            <i class="fad fa-scroll"></i> {{ unreadNotifications }}
          </button>

          <button class="btn" type="button"
            v-if="!isLinked && !group.specialType && isLinkingTask && selectingFor.id != task.id && selectingFor.parentTaskId != task.id"
            style="color: #00a26c"
            @click.stop="finishLinkingTask($event, task)">
            <span class="before-click"><i class="far fa-link"></i> Прикрепить сюда</span>
            <span class="after-click"><i class="far fa-check-circle"></i></span>
          </button>

          <button class="btn" type="button"
            title="Рабочие файлы"
            v-if="!!task.groupId && showFilesButtons"
            v-bind="{'disabled': !task.workingFilesCount}"
            v-bind:class="{'disabled-invisible': !task.workingFilesCount}" 
            style="color: #2d70bd;"
            @click.stop="openWorkingFiles('working', 'Рабочие файлы:')">
            <i v-if="task.workingFilesCount" class="fad fa-file-alt"></i>
            <i v-else class="fal fa-file-alt"  style="opacity: 1"></i>
          </button>
            
          <button class="btn" type="button"
            title="Итоговые файлы PDF"
            v-if="!!task.groupId && showFilesButtons"
            v-bind="{'disabled': !task.finalFilesCount}"
            v-bind:class="{'disabled-invisible': !task.finalFilesCount}"
            style="color: #509E2F;"
            @click.stop="openWorkingFiles('final', 'Итоговые файлы:')">
            <i v-if="task.finalFilesCount" class="fad fa-file-alt"></i>
            <i v-else class="fal fa-file-alt"  style="opacity: 1"></i>
          </button>

          <button class="btn chat" type="button"
            title="Открыть обсуждения задачи"
            v-if="showChatsButton"
            v-bind="{'disabled': !task.id}"
            v-bind:class="{
              'notification': notifications.chatTasks[task.id] && notifications.chatTasks[task.id].length }"
            @click.stop="openChat">
            <i class="fas fa-comments"></i>
          </button>

          <button class="btn btn-primary hidden" type="button"
            title="Добавить файлы/описание к задаче"
            v-if="task.createdById && !task.groupId && addStageUnassignedAllowed"
            @click.stop="$modal.show('PopupAddStage', {
                cell: null,
                cellIndex: -1,
                task: task,
                column: null,
                group: null
            })">
            <i class="fas fa-plus"></i> Добавить...
          </button>

          <button class="btn text-green" type="button"
            title="Переместить задачу"
            v-if="task.id && !task.groupId && allowAssigningTask"
            v-show="!selectingFor"
            @click.stop="startMovingTask(task)">
            <i class="fas fa-share"></i>
            Распределить задачу
          </button>
        </span>
      </span>
  </td>
  <tasks-table-assignable-cell :cell="cell" :task="task" :group="group" :column="group.columns[getRealCellIndex(cell)]" :cell-index="getRealCellIndex(cell)" :isActivated="getRealCellIndex(cell) <= activatedCellIndex" :cell-status-override="index > 0 ? null : getCellStatusOverride(cell)" v-for="(cell, index) in taskCells" :key="cell.id" />
</tr>
</template>

<style scoped>
.fast-subgroups .btn,
.fast-subgroups .btn:active {
    padding: .5em .75em;
    border: none
}

.disabled-invisible {
  opacity: .15;
}

.inline-actions .btn {
  position: relative;
}

.inline-actions .btn.btn-primary {
  background-color: #509E2F;
  border-color: #509E2F;
  color: #FFF;
}

.inline-actions .btn.favorite {
  opacity: 0;
  background-color: transparent;
  color: #ffee00;
}
td.name:hover .inline-actions .btn.favorite,
.inline-actions .btn.favorite.enabled {
  opacity: 1;
}

td.name .row-name {
  min-width: 0;
}
td.name .row-name > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline-actions .btn.chat {
  opacity: .1;
  color: #509E2F;
}
td.name:hover .inline-actions .btn.chat,
.inline-actions .btn.chat.notification,
.inline-actions .btn.stages.notification {
  opacity: 1;
}
.inline-actions .btn.chat.notification {
  color: #ff9900;
}

.inline-actions .btn.stages.notification {
  color: #E40231 !important;
}

.inline-actions .btn .before-click {
  display: inline-block;
  transition: all .2s ease-in-out;
}
.inline-actions .btn .after-click {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: scale(.6);
  transition: all .7s ease-in-out;
}

.inline-actions .btn.clicked .before-click {
  opacity: 0;
  transform: scale(0);
}
.inline-actions .btn.clicked .after-click {
  opacity: 1;
  transform: scale(1.2);
}

.inline-actions .btn.clicked {
  transition: all .5s ease-in-out;
}
.inline-actions .btn.clicked.fade-out {
  opacity: 0;
}


tr.task.z-3 {
  z-index: 1;
  position: relative;
}

.children-folder-icon.has-chat-notifications {
  color: #ff9900;
}
.children-folder-icon.has-notifications,
.row-name .row-name__content.notification {
  color: #E40231;
}
.children-folder-icon.has-chat-notifications.has-notifications {
  text-shadow: 0.5em -0.25em #ff9900;
}
</style>

<script>
import {START_SELECTING_UNASSIGNED, START_LINKING_TASK,
        DONE_LINKING_TASK, SUBSCRIBE_TASK, UNSUBSCRIBE_TASK,
        TASK_SHOW_START, TASK_SHOW_STOP } from "@/store/actions/project"
import { TASK_CHILDREN_OPEN, TASK_CHILDREN_CLOSE } from "@/store/actions/project"
import {
  ASSIGN_TASK, ADD_TASK_UNASSIGNED, ADD_STAGE_ANY,
  EDIT_TASK_LAST_ASSIGNED, EDIT_TASK_ANY,
  VIEW_OWN_TASK_HISTORY, VIEW_ANY_TASK_HISTORY,
  VIEW_OWN_TASK_CHATS, VIEW_ANY_TASK_CHATS
} from "@/utils/permissions"
import { mapGetters } from "vuex"

import {
  FILE_TYPE_WORKING,
  FILE_TYPE_FINAL
} from '@/utils/const'

import DoubleClickEditText from '@/components/DoubleClickEditText.vue'
import Avatar from '@/components/Avatar.vue'
import UserEntry from '@/components/UserEntry.vue'
import TasksTableAssignableCell from './TasksTableAssignableCell.vue'
import TaskGroup from '@/models/taskGroup'
import uuidv4 from '@/utils/uuidv4'

import apiCall from "@/utils/api"

export default {
  name: 'TasksTableRow',
  components: {
    Avatar,
    DoubleClickEditText,
    TasksTableAssignableCell,
    UserEntry
  },
  props: [
    'task',
    'group',  // тут если ссылка, то будет реальная группа в которой лежит задача
    'linksGroup', // а тут если ссылка, то тут будет сама группа "Зависимые вопросы"
    'subgroupsOpened', //открытые подгруппы (вообще все а не только этой задачи)
    'nesting',
    'isLinked',
  ],
  data() {
    return {
        isChildrenOpened: false,
        isUpdatingTask: false
    }
  },
  computed: {
    ...mapGetters(["authId", "authRoles", "notifications", "selectingFor", "isSelectingUnassigned", "isLinkingTask", "isLinkingMultiple", "tasksKeyed", "tasksSubscribed", "taskChildrenOpenedId", 
      "allUsersKeyed", "taskShowing", "taskEditingName", "customerRoles"]),
    user() {
			return this.allUsersKeyed[this.authId]
		},
    isCustomer() {
      return this.customerRoles.includes(this.user.roleId)
    },
    taskCells() {
      if (!this.task || !this.task.cells || !this.task.groupId) {
        return []
      }
      let cells = []
      for (var i=0; i<this.task.cells.length; i++) {
        var column = this.group.columns[i]
        if (column.rolesInvisible.length > 0 && this.isCustomer) {
          continue
        }
        cells.push(this.task.cells[i])
      }
      return cells
    },
    unreadNotifications() {
      if (this.notifications.tasks[this.task.id]) {
        return this.notifications.tasks[this.task.id].length
      }
      return 0
    },
    hasChildren() {
      if (this.isLinked || !this.task.groups || !this.task.groups.length) {
        return false
      }
      if (this.task.linksIn && this.task.linksIn.length) {
        return true
      }
      for (var i=0; i<this.task.groups.length; i++) {
        var hasAnyNonDeletedTask = this.task.groups[i].tasks.some(t => !t.isDeleted)
        if (hasAnyNonDeletedTask) {
          return true
        }
      }
      return false
    },
    // открыты ли подзадачи для текущей группы или нет
    isCurrentChildrenOpened() {
      return this.taskChildrenOpenedId == this.task.id ||
             (!this.isLinked && this.taskChildrenOpenedId == this.group.parentTaskId) ||
             (this.isLinked && this.taskChildrenOpenedId == this.linksGroup.parentTaskId)
    },
    // это именно подгруппы для этой группы
    openedSubgroups() {
      return this.task.groups.filter(g => this.subgroupsOpened.indexOf(g.id) !== -1).map(g => g.id)
    },
    // индекс среди cells (они отсортированы в правильном порядке на сервере)
    // до которого (включительно) ячейки нажимабельны - следующие белые
    activatedCellIndex() {
      if (!this.task.nextActiveCellId) {
        return 0;
      }
      for(var i=0; i<this.task.cells.length; i++) {
        if (this.task.cells[i].id == this.task.nextActiveCellId) {
          return i;
        }
      }
      return 0
    },
    teamIds() {
      var ids = []
      for(var i=0; i<this.task.cells.length; i++) {
        for (var j=0; j<this.task.cells[i].assignedIds.length; j++) {
          ids.push(this.task.cells[i].assignedIds[j])
        }
      }
      return ids
    },
    // назначен ли наш пользователь на любую из ячеек
    isMeAssigned() {
      return this.teamIds.indexOf(this.user.id) !== -1
    },
    showFilesButtons() {
      if (!this.user.isCustomer) {
        return true
      }
      return this.task.cells[this.activatedCellIndex].assignedIds.indexOf(this.user.id) !== -1
    },
    allowAssigningTask() {
      return this.authRoles.indexOf(ASSIGN_TASK) !== -1
    },
    addStageUnassignedAllowed() {
      return this.authRoles.indexOf(ASSIGN_TASK) !== -1 || this.authRoles.indexOf(ADD_TASK_UNASSIGNED) !== -1 || this.authRoles.indexOf(ADD_STAGE_ANY) !== -1
    },
    isEditTaskAllowed() {
      if (!this.task.id || (this.task.createdById && this.task.createdById == this.user.id)) {
        return true
      }
      if (this.authRoles.indexOf(EDIT_TASK_ANY) !== -1) {
        return true
      }
      if (this.user && this.user.isCustomer) {
        return false
      }
      if (this.authRoles.indexOf(EDIT_TASK_LAST_ASSIGNED) !== -1 && this.task.cells.length) {
        let cellIndex = this.task.cells.length-1
        if (this.task.cells[cellIndex].column.rolesAssignable.some(r => this.customerRoles.includes(r))) {
          cellIndex--
        }
        return this.task.cells[cellIndex].assignedIds.indexOf(this.authId) !== -1
      }
      return false
    },
    showHistoryButton() {
      if (this.authRoles.indexOf(VIEW_ANY_TASK_HISTORY) !== -1) {
        return true
      }
      return this.authRoles.indexOf(VIEW_OWN_TASK_HISTORY)!== -1 && this.isMeAssigned
    },
    showChatsButton() {
      if (this.authRoles.indexOf(VIEW_ANY_TASK_CHATS) !== -1) {
        return true
      }
      return this.authRoles.indexOf(VIEW_OWN_TASK_CHATS)!== -1 && this.isMeAssigned
    }
  },
  watch: {
    taskChildrenOpenedId(id) {
      if (this.isChildrenOpened && id != this.task.id) {
        this.toggleChildren()
      }
      if (!this.isChildrenOpened && id == this.task.id) {
        this.toggleChildren()
      }
    },
    taskEditingName(id) {
      if (this.task.id == id || this.task.pendingId == id) {
        this.$refs.taskNameEditor.startEdit()
      }
    }
  },
  methods: {
    getRealCellIndex(cell) {  // index ячейки с учетом пропущенных (которые пользователь не видит)
      return this.task.cells.indexOf(cell)
    },
    getCellStatusOverride(cell) {
      const realIndex = this.getRealCellIndex(cell)
      if (realIndex == 0) {  // если до нее не было пропущенных ячеек то игнорим
        return null
      }
      if (this.task.cells[realIndex].status >= 90) {
        return null
      }

      for (var i=0; i<realIndex; i++) {
        if (this.task.cells[i].assignedIds.length) {
          return 20
        }
      }
      return 0
    },
    onShowEditTask() {
      document.body.click(); // скрываем все открытые tippy
      return true
    },
    onShowTaskTeam() {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      document.body.click(); // скрываем все открытые tippy
      return true
    },
    onAddSubGroup() {
        document.body.click(); // скрываем все открытые tippy
        this.$modal.show('PopupEditTaskGroup', Object.assign(new TaskGroup(), this.group, {
          pendingId: uuidv4(), // temp id in order to understand what actual entry this is
          id: 0,
          name: "",
          color: '#f1f2f3',
          parentGroupId: this.group.id,
          parentTaskId: this.task.id,
          managerIds: this.group.managerIds,
          specialType: null,
          columns: this.group.columns.map(c => Object.assign({}, c, {id: 0}))
        }))
    },
    saveTask() {
      this.isUpdatingTask = true
      let action = this.task.id ? "tasks/edit/"+this.task.id : "tasks/add"
      apiCall({ url: action, method: "POST", data: this.task})
        .then( (/*resp*/) => {
          this.isUpdatingTask = false
        })
    },
    toggleChildren() {
      // нераспределенная
      if (!this.task.groupId || !this.task.id) {
        return
      }
      // если нет подзадач, то ничего не делаем. но если была какая-то другая открыта то закрыавем ее
      if (!this.task.groups.length && !this.task.tasks.length) {
        if (this.taskChildrenOpenedId != this.task.id && (!this.isLinked && this.taskChildrenOpenedId != this.group.parentTaskId || (this.isLinked && this.taskChildrenOpenedId != this.linksGroup.parentTaskId))) {
          this.$store.dispatch(TASK_CHILDREN_CLOSE)
          return
        }
        //return
      }

      // если у задачи ровно одна подгруппа, то ее показываем сразу
      if (this.task.groups.length === 1) {
        this.$emit('openSubgroups', this.task.groups.filter(g => this.openedSubgroups.indexOf(g.id) === -1).map(g => g.id))
      } else if (this.isSelectingUnassigned && this.selectingFor) {
        const isCreatedByCustomer = this.customerRoles.includes(this.allUsersKeyed[this.selectingFor.createdById].roleId)
        const subgroupIds = this.task.groups.filter(g => this.openedSubgroups.indexOf(g.id) === -1 && g.specialType == (isCreatedByCustomer ? 30 : 20)).map(g => g.id)
        this.$emit('openSubgroups', subgroupIds)
      }


      // если уже была нажата задача, но
      // еще не все подпапки открыты, то вторым кликом - открываем все
      /*if (this.isChildrenOpened && this.openedSubgroups.length != this.task.groups.length) {
        this.$emit('openSubgroups', this.task.groups.filter(g => this.openedSubgroups.indexOf(g.id) === -1).map(g => g.id))
        return
      }*/

      // при нажатии на зависимую задачу - ничего не делаем
      if (this.isLinked) {
        return
      }
      // нажали на подзадачу у открытой задачи - ничего не делаем
      if (this.taskChildrenOpenedId && this.taskChildrenOpenedId != this.task.id && this.task.parentTaskId && this.task.parentTaskId == this.taskChildrenOpenedId) {
        return
      }
      this.isChildrenOpened = !this.isChildrenOpened
      if (this.isChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_OPEN, this.task.id)
      } else if (this.task.id == this.taskChildrenOpenedId) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
      }
      let eventName = this.isChildrenOpened ? 'openChildren' : 'closeChildren'
      this.$emit(eventName, this.task.id)
    },
    openEditPopup() {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      // if (this.taskChildrenOpenedId != this.task.id) {
      //   this.toggleChildren()
      // }
      this.$modal.show('PopupEditTask', {
        'task': this.task,
        'group': this.group,
        'cells': this.taskCells,
        'isLinked': this.isLinked,
        'linksGroup': this.linksGroup,
        'contextRef': this.$el
      })
    },

    startMovingTask(task) {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      this.$store.dispatch(START_SELECTING_UNASSIGNED, task)
    },
    startLinkingTask(task) {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      this.$store.dispatch(START_LINKING_TASK, task)
    },
    finishLinkingTask(event, task) {
      // открыта другая задача
      if (!this.isCurrentChildrenOpened && this.taskChildrenOpenedId) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }

      const btn = event.target.closest('.btn')
      btn.classList.add("clicked")
      setTimeout(() => btn.classList.add("fade-out"), 1000)

      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      this.$store.dispatch(DONE_LINKING_TASK, task)
      if (!this.isLinkingMultiple && !this.isChildrenOpened) {
        this.toggleChildren()
      }
    },

    subscribeTask(task) {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      this.$store.dispatch(SUBSCRIBE_TASK, task)
    },
    unsubscribeTask(task) {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      this.$store.dispatch(UNSUBSCRIBE_TASK, task)
    },

    startShowingTask(task) {
      this.$store.dispatch(TASK_SHOW_START, task)
      setTimeout(() => {
        this.$store.dispatch(TASK_SHOW_STOP)
      }, 3500);
    },

    groupHasNotifications(group) {
      if (!group || !group.tasks || !this.notifications || !this.notifications.tasks) {
        return false
      }
      return group.tasks.some(t => this.notifications.tasks[t.id])
    },
    subTasksHasNotifications(task) {
      return task.groups.some(g => this.notifications.groups[g.id]) ||
             task.tasks.some(t => this.notifications.tasks[t.id])
    },
    subTasksHasChatNotifications(task) {
      return task.groups.some(g => this.notifications.chatGroups[g.id]) ||
             task.tasks.some(t => this.notifications.chatTasks[t.id])
    },

    openChat() {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }
      this.$showPanel({component: 'TaskThemes', openOn: 'right', width: 600, props: {'task': this.task}})
    },
    openWorkingFiles(type, subtitle) {
      if (!this.isCurrentChildrenOpened) {
        this.$store.dispatch(TASK_CHILDREN_CLOSE)
        return
      }

      if (type == 'working') {
        type = FILE_TYPE_WORKING
      } else if (type == 'final') {
        type = FILE_TYPE_FINAL
      }
      
      this.$modal.show('PopupWorkingFiles', {task: this.task, fileType: type, subtitle: subtitle })
    }
  }
}
</script>